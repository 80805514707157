const blacklist = [
    'MA', // Morocco
    'DZ', // Algeria
    'TN', // Tunisia
    'SO', // Somalia
    // 'CH', // Switzerland (for testing)
];

const getGeo = async () => {
    const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace');

    const data = await response.text();

    // The uBlock ad blocker blocks the request to the Cloudflare endpoint
    // and no data is returned. In this case we can't determine the country.
    if (typeof data === 'string') {
        return {};
    }

    const ip = data.match(/ip=(.*)/)[1];
    const country = data.match(/loc=(.*)/)[1];
    return { ip, country };
};

const getCountryCode = async () => {
    let country = sessionStorage.getItem('countryCode');
    if (!country) {
        const geo = await getGeo();
        country = geo.country;
        sessionStorage.setItem('countryCode', country);
    }
    return country;
};

export const isBlacklistedCountry = async () => {
    const country = await getCountryCode();
    return blacklist.includes(country);
};
