import { isBlacklistedCountry } from './geo.js';
// import or require use isBlacklisted from ./geo.js

// a function to test if es6 is working

// const { isBlacklisted } = require('./geo.js');

/**
 * Setup the HubSpot form with preselected values.
 */

/**
 * Test URLs
 * 
 * https://m.benedict.ch/index.php?id=101&no_cache=1&tx_benedictmailform_benedictmailform%5BKursname_ausgesucht%5D=KV%20Kaufmann%2F-frau%20mit%20eidg.%20F%C3%A4higkeitszeugnis%20%28EFZ%29%26nbsp%3BE-Profil%20-%20Erweiterte%20Grundausbildung&tx_benedictmailform_benedictmailform%5BStandort_ausgesucht%5D=Z%C3%BCrich
 * 
 * https://www.bvs-bildungszentrum.ch/index.php?id=299&filter-category=9&preselect-course=512
 * https://www.bvs-bildungszentrum.ch/index.php?id=299&filter-category=6,69&preselect-course=581
 * https://reformer-test-page.test/?filter-category=6,69&preselect-course=581
 */

(function () {
  'use strict';

  let hsFormFilterData;
  let courseData = {};

  const formIds = {
    'kursanmeldung': '93892767-e9f1-4875-98d8-40bc3957a80e',
    'beratungsgespraech': 'd46c1f07-7358-4233-b3b9-5264f0f21e71',
    '93892767-e9f1-4875-98d8-40bc3957a80e': 'kursanmeldung',
    'd46c1f07-7358-4233-b3b9-5264f0f21e71': 'beratungsgespraech',
  }


  const isFormBeratungsgespraech = (formId) => {
    return formId === 'd46c1f07-7358-4233-b3b9-5264f0f21e71';
  }


  const preselectRequestType = async ($form, preset) => {

    await new Promise((resolve) => {
      setTimeout(resolve, 200);
    });

    // if preset is set, use it
    if (typeof preset !== 'undefined' && preset.requestType) {
      // preset.requestType is an array that can contain the values "consultation" and "prospectus"
      if (preset.requestType.includes('consultation')) {
        $form.querySelector('input[value="Beratungsgespräch"]').click();
      }

      if (preset.requestType.includes('prospectus')) {
        $form.querySelector('input[value="Prospekt bestellen"]').click();
      }

      if (preset.requestType.includes('trial_lesson')) {
        $form.querySelector('input[value="Probelektion"]').click();
      }

      if (preset.requestType.includes('trialLesson')) {
        $form.querySelector('input[value="Probelektion"]').click();
      }

      return
    }

    if (hasParameterInUrl('preselect-request-type')) {
      const requestType = getParameterFromUrl('preselect-request-type');

      if (requestType.includes('beratung')) {
        $form.querySelector('input[value="Beratungsgespräch"]').click();
      }

      if (requestType.includes('prospekt')) {
        $form.querySelector('input[value="Prospekt bestellen"]').click();
      }

      if (requestType.includes('probelektion')) {
        $form.querySelector('input[value="Probelektion"]').click();
      }
    }

    if (hasParameterInUrl('beratung')) {
      $form.querySelector('input[value="Beratungsgespräch"]').click();
    }

    if (hasParameterInUrl('prospekt')) {
      $form.querySelector('input[value="Prospekt bestellen"]').click();
    }

    if (
      hasParameterInUrl('tx_ssmcourse_pi2%5Baction%5D')
      || hasParameterInUrl('tx_ssmcourse_pi2[action]')
    ) {
      if (
        getParameterFromUrl('tx_ssmcourse_pi2%5Baction%5D') === 'Prospektanfrage'
        || getParameterFromUrl('tx_ssmcourse_pi2[action]') === 'Prospektanfrage'
      ) {
        $form.querySelector('input[value="Prospekt bestellen"]').click();
      }

      if (
        getParameterFromUrl('tx_ssmcourse_pi2%5Baction%5D') === 'Beratungsterminanfrage'
        || getParameterFromUrl('tx_ssmcourse_pi2[action]') === 'Beratungsterminanfrage'
      ) {
        $form.querySelector('input[value="Beratungsgespräch"]').click();
      }
    }
  };

  const preselectCourse = async ($form, preset) => {
    await selectCourseInForm($form, await getPreselectedCourseId(preset));
  };

  const getParameterFromUrl = (param) => {

    const url = new URL(window.location.href);

    return url.searchParams.get(param) || new URLSearchParams(url.hash.slice(1)).get(param);
  }

  const hasParameterInUrl = (param) => {
    return getParameterFromUrl(param) !== null;
  }

  const getPreselectedCourseId = async (preset) => {

    const getCourseIdFromPreset = () => {
      if (typeof preset !== 'undefined' && preset.courseId) {
        return preset.courseId;
      }

      return null;
    }

    let courseId = getCourseIdFromPreset()
      || getParameterFromUrl('tx_ssmcourse_pi2%5BshowUid%5D')
      || getParameterFromUrl('tx_ssmcourse_pi2[showUid]')
      || getParameterFromUrl('preselect-course');

    if (courseId) return courseId;

    // Get last folder from url
    const lastFolder = window.location.pathname
      .replace(/\/$/, '')
      .split('/')
      .pop();

    // get url-course-mapping.json from server
    // const urlCourseMapping = await fetch(
    //   'https://reformer.benedict.clients.sweetcode.com/url-course-mapping.json',
    // );

    const urlCourseMapping = async () => {

      // If mappingJson is stored in sessionStorage, return it
      if (window.sessionStorage && window.sessionStorage.getItem('url-course-mapping')) {
        // console.log('sessionStorage contains mapping');
        return JSON.parse(window.sessionStorage.getItem('url-course-mapping'));
      }

      const mapping = await fetch('https://reformer.benedict.clients.sweetcode.com/url-course-mapping.json')

      const mappingJson = await mapping.json();

      // If sessionStorage is available, save mappingJson in sessionStorage
      if (window.sessionStorage) {
        window.sessionStorage.setItem('url-course-mapping', JSON.stringify(mappingJson));
      }

      return await mappingJson;
    }

    const urlCourseMappingJson = await urlCourseMapping();

    // find lastFolder in url-course-mappingJson keys and return value
    if (courseId = urlCourseMappingJson[lastFolder]) return courseId;

    // Return default course ID
    return 99999
  }

  const setLocation = ($form, locationId) => {

    const locationMap = {
      1: 'Zürich',
      2: 'St. Gallen',
      3: 'Bern',
      4: 'Luzern',
      "Zürich": "Zürich",
      "St. Gallen": "St. Gallen",
      "Bern": "Bern",
      "Luzern": "Luzern",
    };

    // Get the input field that contains the value from location id
    const locationSelect = $form.querySelector('.hs_standort_auswahl');

    // Go through each li element
    // and if its span element contains the text in locationMap[locationId]
    // then select the input element
    for (const li of locationSelect.querySelectorAll('li')) {
      if (li.querySelector('span').innerText === locationMap[locationId]) {
        li.querySelector('input').click();
      }
    }
  };

  const preselectLocation = async ($form, preset) => {

    await new Promise((resolve) => {
      setTimeout(resolve, 200);
    });

    // console.log('preselectLocation')

    await filterLocations($form, await getPreselectedCourseId());
    toggleLocations($form, false);
    setLocation($form, await getLocationPreselection(preset));
  };

  const getLocationPreselection = async (preset) => {

    const locationMap = {
      'zurich': 1,
      'zuerich': 1,
      'Zürich': 1,
      'zürich': 1,
      'st-gallen': 2,
      'St. Gallen': 2,
      'stgallen': 2,
      'st.gallen': 2,
      'st. gallen': 2,
      'bern': 3,
      'Bern': 3,
      'luzern': 4,
      'Luzern': 4,
    };

    const getLocationFromPreset = (preset) => {
      if (typeof preset !== 'undefined' && preset.location) {

        // if location is a number, return it
        if (typeof preset.location === 'number') return preset.location;

        // if location is a string, return the location id from locationMap
        if (typeof preset.location === 'string') return locationMap[preset.location];
      }
    }

    // tx_ssmcourse_pi2[locationUid]
    let locationId =
      getLocationFromPreset(preset)
      || getParameterFromUrl('tx_ssmcourse_pi2%5BlocationUid%5D')
      || getParameterFromUrl('tx_ssmcourse_pi2[locationUid]')
      || getParameterFromUrl('preselect-location');

    const urlLocationName = getParameterFromUrl('tx_benedictmailform_benedictmailform[Standort_ausgesucht]');

    if (urlLocationName) {
      locationId = locationMap[urlLocationName];
    }

    // if (locationId) return locationId;

    // If locationId is numeric and is set, return it
    if (locationId && !isNaN(locationId)) return locationId;

    const pathArray = window.location.pathname.replace(/\/$/, '').split('/');
    // const locationInUrl = pathArray[pathArray.length - 2];

    // Go through each element in pathArray and check if it is in locationMap
    // If it is, return the locationId
    for (const locationName of pathArray) {
      if (locationId == locationMap[locationName]) return locationId;
    }

    return locationMap[locationId];
  }

  const selectCourseInForm = async ($form, courseId) => {

    if (!courseId) return;

    // Find the first option
    // with the value of preselectCourse
    // and mark it selected in pure JavaScript
    const select = $form.querySelector(
      'select[name=\'formular_kurse\']',
    );

    // check if select contains an option with value equals courseId
    // If not, set the courseId to 99999
    if (!select.querySelector(`option[value="${courseId}"]`)) {
      courseId = 99999;
    }

    // select.focus();
    select.click();
    select.value = courseId;
    select.click();

    await filterLocations($form, courseId);
    toggleLocations($form, false);
  };

  const getFormFilterDataFromServer = async () => {

    // if courseData is not empty, return it

    if (Object.keys(courseData).length > 0) {

      // console.log('return cached course data from courseData')
      return courseData;
    }

    // If sessionStorage contains course-data, return it
    if (window.sessionStorage && window.sessionStorage.getItem('course-data')) {

      // console.log('return cached course data from sessionStorage')
      return JSON.parse(window.sessionStorage.getItem('course-data'));
    }

    // console.log('fetch course data from server. cache not available')

    let courseDataUrl;

    // if is localhost, set url to localhost
    if (window.location.hostname === 'reformer-test-page.test') {
      courseDataUrl = 'http://localhost:3001/course-data.json';
    } else if (window.location.hostname.includes('reformer.wolfundbaer.ch')) {
      courseDataUrl = 'https://reformer.benedict.clients.sweetcode.com/course-data.json';
    } else {
      // courseDataUrl = 'https://www.benedict.ch/index.php?id=732&no_cache=1';
      courseDataUrl = 'https://www.benedict.ch/index.php?id=732';
    }

    const response = await fetch(courseDataUrl);
    const responseJson = await response.json();

    courseData = responseJson;

    // If sessionStorage is available, save course-data in sessionStorage
    if (window.sessionStorage) {
      // console.log('save course data to sessionStorage')
      window.sessionStorage.setItem('course-data', JSON.stringify(responseJson));
    }


    // console.log('return course data from server')
    return responseJson;
  };

  // if is localhost return true else return false
  const isLocalhost = () => {
    return window.location.hostname === 'reformer-test-page.test';
  };

  // if is host bvs-bildungszentrum.ch, including all subdomains, return true else return false
  const isHostBvs = () => {
    return window.location.hostname.includes('bvs-bildungszentrum.ch') || window.location.hostname.includes('bbs-business-school.ch') || window.location.hostname.includes('bvs-bbs.ch');
  };

  const isHostBenedict = () => {
    return window.location.hostname.includes('benedict.ch');
  };

  const getBenedictUnitId = () => {
    if (isHostBenedict()) return 'benedict';
    if (isHostBvs()) return 'bvs';
    if (isLocalhost()) return 'bvs';
    // if (isLocalhost()) return 'benedict';
    return 'benedict';
  };

  const getFormFilterData = async () => {
    if (window.sessionStorage && !isLocalhost()) {
      // if (window.sessionStorage) {

      if (
        (hsFormFilterData = JSON.parse(
          window.sessionStorage.getItem('hsFormFilterData'),
        ))
      ) {

        // console.log("return cached form JSON data")
        return hsFormFilterData;
      } else {

        // console.log("return form JSON data from server B")

        hsFormFilterData = await getFormFilterDataFromServer();

        window.sessionStorage.setItem(
          'hsFormFilterData',
          JSON.stringify(hsFormFilterData),
        );


        return hsFormFilterData;
      }
    } else {
      // console.log("return form JSON data from server A")
      return await getFormFilterDataFromServer();
    }
  };

  // https://stackoverflow.com/questions/12073270/sorting-options-elements-alphabetically-using-jquery
  const courseDropdownSort = async ($form) => {
    // get element by name from form element in pure javascript
    const courseDropdown = $form.querySelector('[name="formular_kurse"]');

    // get first option element
    const firstOption = courseDropdown.options[0];

    // remove first option from courseDropdown
    courseDropdown.removeChild(courseDropdown.firstChild);

    // Sort courseDropdown options alphabetically
    // Keep "Kostenlose Laufbahnberatung" (ID 99999) at the top
    const options = Array.from(courseDropdown.options).sort((a, b) => {
      if (a.value == '99999') return -1;
      if (b.value == '99999') return 1;
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });

    // remove all options from courseDropdown
    while (courseDropdown.firstChild) {
      courseDropdown.removeChild(courseDropdown.firstChild);
    }

    // add sorted options to courseDropdown
    options.forEach((option) => {
      courseDropdown.appendChild(option);
    });

    // add first option to to the top of courseDropdown
    courseDropdown.insertBefore(firstOption, courseDropdown.firstChild);
  };

  const toggleLocations = ($form, onoff) => {
    // get element by class from form element in pure javascript
    const locations = $form.querySelector('.hs_standort_auswahl');

    const inputs = locations.querySelectorAll('.hs-input');

    // set input element disabled in pure javascript
    inputs.forEach((input) => {
      input.disabled = onoff;
    });
  };

  const filterLocations = async ($form, courseId) => {

    // console.log('filterLocations')

    if (!courseId) return;

    // Don't filter locations if courseId is 99999 (placeholder)
    if (courseId == 99999) return;

    // Get first element with class .hs_standort_auswahl
    const locationsSelectorUl = $form
      .querySelector('.hs_standort_auswahl')
      .querySelector('ul');

    const formFilterData = await getFormFilterDataFromServer();

    // If formFilterData[courseId] is undefined, return
    if (!formFilterData[courseId]) return;

    // console.log('formFilterData[courseId]: ', formFilterData[courseId])

    const availableLocations = Object.values(formFilterData[courseId].locations);

    // console.log('availableLocations: ', availableLocations)

    // For each locationsSelectorUl li element text value,
    // check if it's value is in availableLocations. If not, remove it
    Array.from(locationsSelectorUl.children).forEach((li) => {
      if (availableLocations.includes(li.textContent) === false) {
        // Make li element invisible
        li.style.display = 'none';
        // unselect radio element li
        li.querySelector('input').checked = false;
      } else {
        // Make li element visible
        li.style.display = '';
      }
    });
  };

  const courseDropdownRemoveCoursesWithNoLocation = async ($form) => {

    // console.log('courseDropdownRemoveCoursesWithNoLocation')

    // get element by name from form element in pure javascript
    const dropdown = $form.querySelector('[name="formular_kurse"]');

    // get options from courseDropdown
    const optionList = Array.from(dropdown.options);

    const formFilterData = await getFormFilterDataFromServer();

    // console.log('formFilterData: ', formFilterData)

    // go through all options and remove if no location is available
    optionList.forEach((key, value) => {

      // key is an option element like this <option value="498">Three-year BA Degree in Global/Business Management</option>
      // Get the value 498 from it
      const elementId = key.value;

      // console.log('key: ', key)
      // console.log('value: ', value)
      // console.log('value.value: ', value.value)

      if (formFilterData[elementId]?.locations?.length === 0) {
        // value.remove();

        // remove option from dropdown
        dropdown.removeChild(dropdown.querySelector(`option[value="${elementId}"]`));
      }
    });
  };

  const courseDropdownFilterByUnit = async ($form, unit) => {

    // get element by name from form element in pure javascript
    const courseDropdown = $form.querySelector('[name="formular_kurse"]');

    // get options from courseDropdown
    const optionElements = Array.from(courseDropdown.options).map(
      (option) => option,
    );

    const formFilterData = await getFormFilterDataFromServer();

    // go through all options and remove if wrong unit
    optionElements.forEach((element) => {

      // console.log('element.value: ', element.value)
      if (!element.value) return;

      if (!formFilterData[element.value]?.unit?.includes(unit)) {
        element.remove();
      }
    });
  };

  const courseDropdownFilterByLocation = async (dropdown, locationId) => {

    // console.log('courseDropdownFilterByLocation')
    // console.log("dropdown: ", dropdown)
    // console.log("locationId: ", locationId)

    // get options from courseDropdown
    const optionElements = Array.from(dropdown.options).map(
      (option) => option,
    );

    // console.log('optionElements: ', optionElements)

    const formFilterData = await getFormFilterDataFromServer();

    // console.log("formFilterData: ", formFilterData)

    // go through all options and remove if wrong unit
    optionElements.forEach((element) => {
      if (!element.value) return;

      if (!formFilterData[element.value]?.locations || !(locationId in formFilterData[element.value]?.locations)) {
        element.remove();
      }
    });
  };

  const filterCoursesByCategoryWhiteList = async ($form, preset) => {

    // console.log('filterCoursesByCategoryWhiteList')
    // console.log('preset', preset)

    const getCategoriesFromPreset = (preset) => {

      if (typeof preset !== 'undefined' && preset.groupIds && preset.groupIds.length > 0) {

        // change each value to string
        preset.groupIds = preset.groupIds.map((value) => {
          return value.toString();
        });

        return preset.groupIds;
      }

      return null;
    }

    const getCategoriesFromUrl = () => {

      if (!getParameterFromUrl('filter-category')) {
        return null;
      }

      return getParameterFromUrl('filter-category').split(',');
    }

    const filterCategories = getCategoriesFromPreset(preset) || getCategoriesFromUrl();

    if (!filterCategories) {
      return;
    }

    const courseDropdown = $form.querySelector('[name="formular_kurse"]');

    // Get option elements
    // from form courseDropdown in pure javascript as node list
    const optionElements = Array.from(courseDropdown.options).map(
      (option) => option,
    );

    const formFilterData = await getFormFilterDataFromServer();

    optionElements.forEach((element) => {

      if (!element.value) return;

      // Keep kostenlose Laufbahnberatung (ID 99999) in dropdown
      if (element.value === '99999') return;

      if (!formFilterData[element.value]?.categories) {

        // if courseDropdown.querySelector(`option[value="${element.value}"]`) exists,
        // remove it

        if (courseDropdown.contains(
          courseDropdown.querySelector(`option[value="${element.value}"]`),
        )) {
          courseDropdown.removeChild(
            courseDropdown.querySelector(`option[value="${element.value}"]`),
          );
        }

        // courseDropdown.removeChild(
        //   courseDropdown.querySelector(`option[value="${element.value}"]`),
        // );
        return;
      }

      // Make array from object
      // formFilterData[value].categories using the keys as array
      const categories = Object.keys(formFilterData[element.value].categories);

      // If formFilterData[element.value].categories
      // does not contain any of the values in filterCategoriesArray,
      // remove the element from the dropdown
      if (
        categories.some((category) =>
          filterCategories.includes(category),
        ) === false
      ) {

        if (courseDropdown.contains(
          courseDropdown.querySelector(`option[value="${element.value}"]`),
        )) {
          courseDropdown.removeChild(
            courseDropdown.querySelector(`option[value="${element.value}"]`),
          );
        }
      }
    });
  };

  // This is an array of all Sprachschule category IDs
  const sprachschuleCategoryIdsArray = [
    '20', '21', '22', '23', '24', '28', '34', '36', '37', '38', '39', '40', '41', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '66', '67'];


  const filterCoursesByCategoryBlackList = async ($form, preset) => {

    let categoryIdsBlackList = [];

    // if we're on the BVS domain add the Sprachschule category IDs to the blacklist
    if (isHostBvs()) {
      categoryIdsBlackList = sprachschuleCategoryIdsArray;
    }

    // Remove all courses from the dropdown that have a category ID in categoryIdsBlackList
    const courseDropdown = $form.querySelector('[name="formular_kurse"]');
    const optionElements = Array.from(courseDropdown.options).map(
      (option) => option,
    );

    const formFilterData = await getFormFilterDataFromServer();

    optionElements.forEach((element) => {

      if (!element.value) return;

      // Keep kostenlose Laufbahnberatung (ID 99999) in dropdown
      if (element.value === '99999') return;

      if (!formFilterData[element.value]?.categories) {

        // if courseDropdown.querySelector(`option[value="${element.value}"]`) exists,
        // remove it
        if (courseDropdown.contains(
          courseDropdown.querySelector(`option[value="${element.value}"]`),
        )) {
          courseDropdown.removeChild(
            courseDropdown.querySelector(`option[value="${element.value}"]`),
          );
        }

        return;
      }

      // Make array from object
      // formFilterData[value].categories using the keys as array
      const categories = Object.keys(formFilterData[element.value].categories);

      // If formFilterData[element.value].categories
      // does not contain any of the values in filterCategoriesArray,
      // remove the element from the dropdown
      if (
        categories.some((category) =>
          categoryIdsBlackList.includes(category),
        ) === true
      ) {

        if (courseDropdown.contains(
          courseDropdown.querySelector(`option[value="${element.value}"]`),
        )) {
          courseDropdown.removeChild(
            courseDropdown.querySelector(`option[value="${element.value}"]`),
          );
        }
      }
    });
  }

  /**
   * Only keep a course in the dropdown
   * if it belongs to the unit that is determined by the current hostname
   * If the hostname is bvs-bbs.ch then keep the courses that belong to the unit bvs
   * For Benedict, the unit is benedict
   **/
  const filterFormByUnit = async ($form, preset) => {

    // console.log('filterFormByUnit')

    const unit = getBenedictUnitId();

    const courseDropdown = $form.querySelector('[name="formular_kurse"]');
    const optionElements = Array.from(courseDropdown.options).map(
      (option) => option,
    );

    const formFilterData = await getFormFilterDataFromServer();

    optionElements.forEach((element) => {

      if (!element.value) return;

      // Keep kostenlose Laufbahnberatung (ID 99999) in dropdown
      if (element.value === '99999') return;

      if (!formFilterData[element.value]?.unit) {

        // if courseDropdown.querySelector(`option[value="${element.value}"]`) exists,
        // remove it
        if (courseDropdown.contains(
          courseDropdown.querySelector(`option[value="${element.value}"]`),
        )) {
          courseDropdown.removeChild(
            courseDropdown.querySelector(`option[value="${element.value}"]`),
          );
        }

        return;
      }

      // Make array from object
      // formFilterData[value].categories using the keys as array
      const units = formFilterData[element.value].unit;

      // If formFilterData[element.value].categories
      // does not contain any of the values in filterCategoriesArray,
      // remove the element from the dropdown
      if (
        units.some((unitId) =>
          unitId.includes(unit),
        ) === false
      ) {

        if (courseDropdown.contains(
          courseDropdown.querySelector(`option[value="${element.value}"]`),
        )) {
          courseDropdown.removeChild(
            courseDropdown.querySelector(`option[value="${element.value}"]`),
          );
        }
      }
    });
  }


  const isSprachschulePage = async () => {

    const courseParameters = [
      "Englisch",
      "Deutsch",
      "Spanisch",
      "Französisch",
      "Franz",
      "Italienisch",
      "Russisch",
      "Chinesisch",
      "Japanisch",
      "Arabisch",
      "Türkisch",
      "sprach",
    ];

    const langSchoolCourseIds = [
      "99991",
      "99998",
      "99997",
      "99996",
      "99995",
      "99994",
    ]

    // If the URL path contains the word "Sprachschule", 
    // or if a parameter "course" exists and contains (even if partially) one of the values in courseParameters,
    // return true

    if (window.location.pathname.includes('Sprachschule')) {
      return true;
    }

    if (new URLSearchParams(window.location.search).get('course')) {

      const course = new URLSearchParams(window.location.search).get('course');

      if (courseParameters.some((courseParameter) => course.includes(courseParameter))) {
        return true;
      }
    }

    if (getParameterFromUrl("preselect-course")) {
      const preselectCourse = getParameterFromUrl("preselect-course");

      if (langSchoolCourseIds.includes(preselectCourse)) {
        return true;
      }

      // check it the name of the course contains "sprach", if so return true
      const formFilterData = await getFormFilterDataFromServer();

      // console.log('formFilterData: ', formFilterData)

      if (formFilterData[preselectCourse].name.toLowerCase().includes('sprach')) {
        return true;
      }

      // If the name contains one of the values in courseParameters, return true
      if (courseParameters.some((courseParameter) => formFilterData[preselectCourse].name.includes(courseParameter))) {
        return true;
      }

      // If one of the categorie names contains "sprach", return true
      if (Array.isArray(formFilterData[preselectCourse].categories) && formFilterData[preselectCourse].categories.length > 0) {
        if (formFilterData[preselectCourse].categories.some((category) => category.toLowerCase().includes('sprach'))) {
          return true;
        }
      }
    }

    // If preset.groupIds exists and contains (even if partially) one of the values in sprachschuleCategoryIdsArray,
    // return true

    if (typeof preset !== 'undefined' && preset.groupIds && preset.groupIds.length > 0) {
      return preset.groupIds.some((courseGroupId) => sprachschuleCategoryIdsArray.includes(courseGroupId));
    }

    // if (
    //   window.location.pathname.includes('Sprachschule')
    //   || (new URLSearchParams(window.location.search).get('course') && courseParameters.includes(new URLSearchParams(window.location.search).get('course')))
    // ) {
    //   return true;
    // }

    return false;
  }

  // Filter the selection boxes of the field "Anfrage Typ Auswahl"
  // Remove the options for "Kostenlose Probelektion" everywhere except when the form is shown on 
  // a page for Sprachkurse which contains the word "Sprachschule" in the URL path
  const filterAnfrageTyp = async ($form, preset) => {

    // Get element by class from form element in pure javascript. The class name is "hs_anfrage_typ_auswahl"
    const anfrageTypCheckbox = $form.querySelector('.hs_anfrage_typ_auswahl');

    // Get the first child div that contains the class "input"
    const anfrageTypDropdown = anfrageTypCheckbox.querySelector('div.input');

    // anfrageTypDropdown contains an unordered list with list items. 
    // Each list item contains a label element wich contains a span element. 
    // Remove the list item that contains the span element with the text "Kostenlose Probelektion"

    const anfrageTypDropdownListItems = anfrageTypDropdown.querySelectorAll('li');

    anfrageTypDropdownListItems.forEach(async (listItem) => {

      const anfrageTypDropdownLabel = listItem.querySelector('label');
      const anfrageTypDropdownSpan = anfrageTypDropdownLabel.querySelector('span');

      // If anfrageTypDropdownSpan.innerText contains the text "Kostenlose Probelektion", remove the list item,
      // unless we're on a Sprachschule page
      if (
        anfrageTypDropdownSpan.innerText.includes("Probelektion")
        && !(await isSprachschulePage())
      ) {
        listItem.remove();
      }
    });
  }

  const detectCourseChange = ($form) => {

    // Detect if sel has changed
    $form
      .querySelector('select[name="formular_kurse"]')
      .addEventListener('change', async (event) => {

        // console.log('course changed')
        // get selected option element
        const selectedOption = event.target.options[event.target.selectedIndex];

        // If selected option is not the first
        // or the second option, pass the value to filterLocations

        if (selectedOption.value !== '0' && selectedOption.value !== '1') {
          await filterLocations($form, selectedOption.value);
        }
      });
  };

  const checkIfCourseAndLocationAreAvailable = async ($form) => {

    const locationMap = {
      1: 1, // Zürich
      2: 5, // St. Gallen
      3: 4, // Bern
      4: 3, // Luzern
    }

    // Check if location has been pre selected
    const location = await getLocationPreselection()

    if (!location) return false

    // Check if course has been pre selected
    const course = await getPreselectedCourseId()

    if (!course) return false

    // Check if course and location are available
    const formFilterData = await getFormFilterDataFromServer();

    if (!formFilterData[course] || !formFilterData[course].locations[locationMap[location]]) {
      showCourseNotAvailableMessage($form)
      return false
    }

    return true
  }

  // Insert a div below the course dropdown with the text 
  // "Der gewählte Kurs ist leider nicht verfügbar. Bitte wählen Sie einen anderen Kurs aus."
  const showCourseNotAvailableMessage = ($form) => {

    console.log('course is not available')

    // Get course dropdown
    const courseDropdown = $form.querySelector('[name="formular_kurse"]')

    // Create div element
    const div = document.createElement('div')

    // Add styling to div if not on blog page
    if (!isBlogPage()) {
      // div.style.backgroundColor = '#f2dede'
      div.style.color = 'red'
      div.style.fontWeight = 'bold'
      div.style.marginTop = '10px'
    }

    // Add text to div
    div.innerText = 'Der gewählte Kurs ist leider nicht verfügbar. Bitte wählen Sie einen anderen Kurs aus.'

    // Insert div after course dropdown
    courseDropdown.after(div)

    // Add event listener to course dropdown
    courseDropdown.addEventListener('change', (event) => {

      // Remove div
      div.remove()

      // Remove class from course dropdown
      courseDropdown.classList.remove('course-not-available')
    })
  }

  const registerLocationChangeEvents = ($form) => {

    // Get element with class .hs_standort_auswahl and then get element with class .input
    const locationInput = $form
      .querySelector('.hs_standort_auswahl')
      .querySelector('.input');

    // Register event listener on locationInput
    locationInput.addEventListener('change', async (event) => {

      // console.log('location changed')

      if ($form, event.target.value) {

        // console.log("event.target.value: ", event.target.value)

        updateCourseDropdown($form, event.target.value)
      }
    })
  };

  const updateCourseDropdown = async ($form, location) => {

    let dropdown = $form.querySelector('[name="formular_kurse"]')

    // reset course dropdown
    let courseId = $form.querySelector('[name="formular_kurse"]').options[dropdown.selectedIndex].value

    // if the courseId is any of the placeholder values, then set it to the preset value
    if (courseId == '' || courseId == 99999) {
      courseId = $form.preset.courseId
    }

    let originalDropdown = $form.dropdown;

    // Replace the current dropdown with the original clone
    dropdown.parentNode.replaceChild(originalDropdown, dropdown);

    // Since the original clone is now in the DOM and the reference is moved, clone it again for future resets
    $form.dropdown = originalDropdown.cloneNode(true);

    // Rerefence the new dropdown
    dropdown = $form.querySelector('[name="formular_kurse"]')

    await prepareCourseDropdown($form, $form.preset)

    // await prepCourseSelection(getHsForm())
    // await prepareAllFormFields($form, $form.preset)

    await courseDropdownFilterByLocation(dropdown, getBenedictLocationIdByText(location))

    // console log if dropdownCurrent is has an option with value courseId to select
    if (dropdown.querySelector(`option[value="${courseId}"]`)) {
      dropdown.value = courseId
      dropdown.style.color = 'black'
    } else {
      console.log('selected course is not available in this location')
    }

    detectCourseChange($form)
    // console.log('updateCourseSelection')
  }

  const getBenedictLocationIdByText = (text) => {

    // prettier-ignore
    const locationMap = {
      'Zürich': 1,
      'St. Gallen': 5,
      'Bern': 4,
      'Luzern': 3,
    }

    return locationMap[text]
  }

  /** 
   * Remove all html tags from the course dropdown options
   * Sort the course dropdown options alphabetically
   * Remove all courses that are not available in the current location
   * Filter the course dropdown by the current unit
   */
  const prepCourseDropdownFormatting = async ($form) => {
    await removeHtmlTagsFromTitles($form);
    await courseDropdownSort($form);
  };

  const getHsForm = () => {

    // If we're using the preformatted form, get the form from the iframe
    const hsFormIframe = document.getElementById('hs-form-iframe-0');

    if (hsFormIframe) {
      return hsFormIframe.contentDocument || hsFormIframe.contentWindow.document
    }

    // If we're using the form with custom formatting, get the form by ID
    return document.getElementById('hsForm_d46c1f07-7358-4233-b3b9-5264f0f21e71');
  }

  const onFormSubmitted = async ($form, formId, data) => {

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      'event': 'hubspot-form-success',
      'hs-form-guid': $form.dataset.instanceId, // temp
      'hsFormDataset': $form.dataset, // temp
      'hsForm': $form, // temp
      'hsData': data, // temp
      'hsFormId': formId,
      'hsSubmissionValues': data.submissionValues, // for benedict.ch GTM
      'ecd': data.submissionValues, // for bvs-bbs.ch GTM
    });
  }

  const removeHtmlTagsFromTitles = async ($form) => {

    const courseDropdown = $form.querySelector('[name="formular_kurse"]');

    const optionElements = Array.from(courseDropdown.options).map(
      (option) => option,
    );

    optionElements.forEach((element) => {

      if (!element.value) return;

      // Remove all html tags from the option text
      element.text = element.text.replace(/<[^>]*>?/gm, ' - ');
    }
    );
  }

  /**
   * This function updates the formatting of the form
   */
  const updateFormFormatting = async ($form) => {

    if (isHostBvs()) {
      $form.querySelector('.hs-button').style.backgroundColor = '#1397BB'
      $form.querySelector('.hs-button').style.borderColor = '#1397BB'
    }

    // make all placehorder text colors black
    // $form.querySelectorAll('input').forEach((input) => {
    //   input.style.color = 'black'
    // })

    // make select.is-placeholder color black
    $form.querySelectorAll('select').forEach((select) => {
      select.style.color = 'black'
    })
  }

  const addScriptsToBVSForm = async ($form) => {

    if (!isHostBvs()) {
      return
    }

    // Add a Hotjar tracking script to the form
    const hotjarScript = document.createElement('script');
    hotjarScript.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3896175,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;

    // Add the Hotjar script to the form
    $form.appendChild(hotjarScript);
  }

  const onFormReady = async ($form, preset) => {

    // save the current preset in the $form
    $form.preset = preset;
    // console.log("$form.preset", $form.preset)

    $form.dropdown = $form.querySelector('[name="formular_kurse"]').cloneNode(true);
    // console.log("$form.dropdown saved", $form.dropdown )

    // prepareCourseDropdown($form, preset);

    updateFormFormatting($form)
    addScriptsToBVSForm($form)

    await prepareAllFormFields($form, preset);

    // Add event listener to detect changes on the course input and rerun the location filter
    registerFormEventListeners($form)
  }

  const registerFormEventListeners = async ($form) => {

    detectCourseChange($form);
    registerLocationChangeEvents($form);
    registerCardButtonListener($form);
  }

  const prepareAllFormFields = async ($form, preset) => {

    await prepareCourseDropdown($form, preset);

    const courseAndLocationAvailable = await checkIfCourseAndLocationAreAvailable($form)

    if (courseAndLocationAvailable || await getPreselectedCourseId()) {
      // We need to preselect the course twice, once before filtering the selection and once after because of Firefox
      await preselectCourse($form, preset);
    }
    // if the preset.formId is not set or if isFormBeraungsgespraech() then run a function
    if (!preset?.formId || isFormBeratungsgespraech(preset.formId)) {
      await filterAnfrageTyp($form, preset);
    }

    // toggleLocations($form, true);

    if (courseAndLocationAvailable || await getPreselectedCourseId()) {
      // We need to preselect the course twice, once before filtering the selection and once after because of Firefox
      await preselectCourse($form, preset);
      await preselectLocation($form, preset);
    }

    await preselectRequestType($form, preset);
  }

  const prepareCourseDropdown = async ($form, preset) => {

    // console.log('prepareCourseDropdown')
    // Reformat, sort, etc. the course dropdown
    await prepCourseDropdownFormatting($form);

    // await courseDropdownRemoveCoursesWithNoLocation($form);
    // await courseDropdownFilterByUnit($form, getBenedictUnitId());
    // await filterCoursesByCategoryWhiteList($form, preset);
    // await filterFormByUnit($form, preset);
    // await filterCoursesByCategoryBlackList($form, preset);

    await filterToShowSingleCourse($form, preset);
  }

  const filterToShowSingleCourse = async ($form, preset) => {

    // Get the course Id from the preset or from the URL query parameter
    const courseId = await getPreselectedCourseId(preset);

    // preset.courseId is set to a specific course
    // remove all other courses from the dropdown
    if (!courseId) return

    const courseDropdown = $form.querySelector('[name="formular_kurse"]');

    // Get option elements
    // from form courseDropdown in pure javascript as node list
    const optionElements = Array.from(courseDropdown.options).map(
      (option) => option,
    );

    let courseFound = false;

    if (courseId == 99999) {
      courseFound = true;
    }

    // Go through all element in optionElements and check if they contain courseId
    optionElements.forEach((key, value) => {
      if (key.value == courseId) {
        courseFound = true;
      }
    });

    // If the course is found in the dropdown, remove all other courses
    // If it is not found, keep course with ID 99999
    optionElements.forEach((key, value) => {

      // Always keep "kostenlose Laufbahnberatung" in the dropdown
      // if the elementId is 99999, then skip
      if (courseFound === false && key.value == 99999) return

      if (key.value == courseId) return

      key.remove();
    });

    if (!courseFound) {
      console.error(`Course ${courseId} not found in dropdown`)

      // https://wolfundbaer.slack.com/services/6917370833826?updated=1
      const url = "https://hooks.slack.com/services/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" // Slack webhook URL to #wb-x-benedict

      // Send a message to Slack webhook URL: https://hooks.slack.com/services/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
      // Message: "Course not found in dropdown" with the course ID
      fetch(url, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: `Course ID ${courseId} not available in the form, URL: ${window.location.href}, Referrer: ${document.referrer}`,
        }),
      })
    }
  }

  const registerCardButtonListener = ($form) => {

    /**
     * After the the website has loaded,
     * or if it is loaded already,
     * register an event listener to all elements with the dta-cata-type attribute.
     * Once clicked, console log all data attributes of the element.
     **/

    document.addEventListener('click', function (event) {
      // Check if the clicked element is a button and if its parent has the 'data-cta-type' attribute
      if (event.target.tagName === 'BUTTON' && event.target.closest('[data-cta-type]')) {

        const parentDiv = event.target.closest('[data-cta-type]');

        // If the data-cta-location attribute is set, set the location in the form
        if (parentDiv.getAttribute('data-cta-location')) {
          setLocation($form, parentDiv.getAttribute('data-cta-location'))
        }

        // If the data-cta-type attribute is set, set the request type in the form
        if (parentDiv.getAttribute('data-cta-type')) {
          selectAnfrageTyp($form, parentDiv.getAttribute('data-cta-type'))
        }

        // updateCourseSelectionOnFormInFocus($form)

        // if($form.preset) {
        //   updateAllFormFields($form, $form.preset);
        // }


      }
    });
  }

  /**
   * This function selects a specific checkbox within a form based on the provided request type.
   * 
   * @param {HTMLElement} $form - The form element that contains the checkboxes.
   * @param {string} anfrageTyp - The request type to select. This can be one of the following: 'beratungsgespraech', 'Beratungsgespräch', 'Beratung', 'Broschüre'.
   * 
   * The function first deselects all checkboxes within the '.hs_anfrage_typ_auswahl' div of the form.
   * Then it selects the checkbox that corresponds to the provided request type.
   * If the provided request type does not match any of the checkboxes, no checkbox is selected.
   */
  const selectAnfrageTyp = ($form, anfrageTyp) => {

    // There is one div that contains a class hs_anfrage_typ_auswahl
    // It contains checkbox inputs
    // Deslect all checkboxes

    const anfrageTypCheckbox = $form.querySelector('.hs_anfrage_typ_auswahl');

    if (!anfrageTypCheckbox) {
      return;
    }

    const inputs = anfrageTypCheckbox.querySelectorAll('.hs-input');

    // set input element disabled in pure javascript

    inputs.forEach((input) => {
      input.checked = false;
    });

    // Select the checkbox with the value of anfrageTyp

    const requestType = {
      'beratungsgespraech': 'Beratungsgespräch',
      'Beratungsgespräch': 'Beratungsgespräch',
      'Beratung': 'Beratungsgespräch',
      'Broschüre': 'Prospekt bestellen',
    }

    const input = anfrageTypCheckbox.querySelector(`input[value="${requestType[anfrageTyp]}"]`);

    if (input) {
      input.checked = true;
    }
  }

  // https://stackoverflow.com/a/61903296/4688612
  const getScript = (url) =>
    new Promise((resolve, reject) => {

      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      // script.defer = true;

      script.onerror = reject;

      script.onload = script.onreadystatechange = function () {
        const loadState = this.readyState;

        if (loadState && loadState !== 'loaded' && loadState !== 'complete') {
          return;
        };

        script.onload = script.onreadystatechange = null;

        resolve();
      };

      document.head.appendChild(script);
    });

  const loadCss = async (preset) => {

    let css

    if (preset?.css) return preset.css

    if (preset?.cssPath) {
      css = await getCssFromPath(preset.cssPath)

      if (css) {
        return css
      }
    }

    if (
      isBlogPage()
      || window.location.search.includes('disableHsCss')
    ) {

      let css = await getBlogCss()

      if (css) {
        return css
      }
    }

    return null
  }

  const getCssFromPath = async (path = 'https://www.benedict.ch/assets/css/form.css') => {

    let css = null

    try {

      const response = await fetch(path, {
        cache: "default",
        // mode: "no-cors",
      })

      css = await response.text()
    } catch (error) {
      console.error(error)
      console.error('Could not load css from path', path)
    }

    return css
  }

  const getFormId = (preset) => {

    if (preset?.formId) return preset.formId

    // fallback
    return "d46c1f07-7358-4233-b3b9-5264f0f21e71"
  }

  const getFormTarget = (preset) => {

    if (preset?.target) return preset.target

    // fallback
    return "#hs-form-container"
  }

  // Load a formspree.io backup form
  const loadBackupForm = async () => {

    const formHtml = await getFormspreeForm()

    // Get the external CSS from getBlogCss() and inject it into the head
    const css = await getBlogCss()

    console.log('try to inject css')

    if (css) {

      console.log('css', css)
      const style = document.createElement('style')
      style.innerHTML = css
      document.head.appendChild(style)
    }

    // Inject the form into the div with the ID hs-form-container
    document.getElementById('hs-form-container').innerHTML = formHtml
  }

  // Load the file formspree-backup-1.html from the local disk and return its contents
  const getFormspreeForm = async () => {

    let response

    const fileName = 'formspree-backup-1.html'

    if (isLocalhost()) {
      response = await fetch('http://localhost:3001/' + fileName)
    } else {
      response = await fetch('https://reformer.benedict.clients.sweetcode.com/' + fileName)
    }
    const html = await response.text()

    return html
  }

  const isBlogPage = () => {

    // If we are on the domain https://blog.benedict.ch/ return true
    if (
      window.location.hostname === 'blog.benedict.ch'
      // || window.location.hostname === 'reformer-test-page.test'
    ) {
      return true
    }

    return false
  }

  // Return the CSS for the blog page from https://www.benedict.ch/assets/css/form.css
  const getBlogCss = async () => {

    try {

      // Temporarily disable external CSS on blog page
      if (isBlogPage()) {
        console.log('Is blog page. Temporarily disable external css')
        return ''
      }

      // For testing, if is on localhost, return predifined CSS
      if (isLocalhost()) {
        return `
        body {
          background: #f2f2f2;
        }
        `
      }

      const response = await fetch('https://www.benedict.ch/assets/css/form.css')
      const css = await response.text()

      return css

    } catch (error) {
      console.error(error)
      return ''
    }
  }

  const loadHsForm = async (preset) => {

    let data = {
      region: 'na1',
      portalId: '6603468',
      formId: getFormId(preset),
      target: getFormTarget(preset),
      // css: '',
      // cssClass: '',
      onFormReady: ($form) => {

        // If $form is a <form> element, continue
        // If the <form> element is in the first index of the array, set $form to the first element in the array
        if ($form instanceof Object) {
          $form = $form[0]
        }

        onFormReady($form, preset)
      },
      onFormSubmitted: ($form, data) => {

        if ($form instanceof Object) {
          $form = $form[0]
        }

        onFormSubmitted($form, getFormId(preset), data)
      }
    }

    const css = await loadCss(preset)
    if (css) data.css = css

    //  data.cssClass = "hubspot-form"
    // If there is a URL parameter called "backupform" load the backup form
    if (window.location.search.includes('backupform') || window.location.search.includes('forceBackupForm')) {

      console.log('load backup form')
      await loadBackupForm()

    } else {

      hbspt.forms.create(data)
    }
  }

  getScript('https://js.hsforms.net/forms/v2.js')
    .then(async () => {

      // console.log("is blacklisted country: ", await isBlacklisted())
      // Abort if the country is blacklisted
      if (await isBlacklistedCountry()) return

      // if reformerPreset is defined and an an object,
      // pass it to loadsForm() to load the form with the preset values
      // But if reformerPreset is an array of objects, loop through it and load each form with each object

      // console.log("loading form filter data from server")
      // start measuring time
      // const startTime = performance.now()
      await getFormFilterDataFromServer()
      // end measuring time
      // const endTime = performance.now()
      // console.log('loading form filter data from server took ' + (endTime - startTime) + ' milliseconds')

      if (typeof reformerPreset !== 'undefined' && reformerPreset instanceof Array) { // reformerPreset is set and is an array

        reformerPreset.forEach(async (preset) => {
          await loadHsForm(preset)
        })

      } else if (typeof reformerPreset !== 'undefined') { // reformerPreset is an object
        await loadHsForm(reformerPreset)
      } else { // no configuration found, 

        console.log('no preset found. use default values')

        let preset = {
          // courseId: 99999,
          // groupIds: [6, 69],
          // requestType: [],
          // location: "zuerich",
          // css:"background-color: blue",
          // cssClass: ".MY-CSS-CLASS-NAME",
          // cssPath: "https://www.benedict.ch/assets/css/form.css",
          target: document.querySelector('#hs-form-container') ? '#hs-form-container' : '#hs-form-container-1',
        }

        // try to get preset values from the URL (path and query string) and overwrite the default values

        await loadHsForm(preset)
      }

    })
    .catch(async (error) => {

      if (await isBlacklistedCountry()) return

      await loadBackupForm();
      console.error('Could not load script: ' + error);
    });

  console.log('reformer.js loaded');

})();

